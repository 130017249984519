import React, {useState} from 'react';
import {Dialog} from "primereact/dialog";
import './Dialog.scss';

const TermsDialog = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    return (
        <div className={"dialog-wrapper"}>
            <Dialog visible={isDialogOpen} onHide={() => setIsDialogOpen(false)} style={{width: "100%"}}>
                <div className={"dialog-content"}>
                    <br/><h1>REGULAMIN KORZYSTANIA Z APLIKACJI </h1>
                    <br/><p>Niniejszy Regulamin określa zasady korzystania przez Ciebie z naszej aplikacji Bookit.One, określa rodzaj i zakres usług dostępnych w aplikacji oraz zasady ich reklamowania. </p>
                    <br/>
                    <br/><h2>O nas</h2>
                    <br/>Nasza aplikacja Bookit.One zajmuje się pośrednictwem w sprzedaży usług naszych kontrahentów. W obrocie prawnym działamy w formie spółki z ograniczoną odpowiedzialnością pod nazwą Bookit.One. Nasza siedziba mieści się we Wrocławiu. Zostaliśmy wpisani do rejestru przedsiębiorców przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000908641, NIP 8982267179, REGON 389327573.
                    <br/>
                    <br/>Jeżeli chcesz się z nami skontaktować możesz to uczynić poprzez e-mail: kontakt@bookit.one lub za pośrednictwem naszych profili w social media: https://www.facebook.com/Bookit.One .
                    <br/>
                    <br/><h2>Jakie usługi świadczymy?</h2>
                    <br/>Za pośrednictwem naszej aplikacji oferujemy Ci możliwość zakupu usług lub voucherów prezentowych oferowanych przez współpracujące z nami strzelnice.
                    <br/>
                    <br/>1)	Usługi to usługi związane ze strzelectwem, oferowane przez naszych kontrahentów. W aplikacji znajdziesz dokładny opis usługi oraz będziesz mógł wybrać termin jej realizacji za pomocą udostępnionego Ci kalendarza.
                    <br/>
                    <br/>2)	Pakiet usług to zestawienie oferowanych przez naszego kontrahenta usług połączone w jeden pakiet, który może zostać przez Ciebie zakupiony za pośrednictwem naszej aplikacji. Każdy oferowany pakiet usług składa się z opisu, ceny i listy usług wchodzących w skład pakietu. Pakiet usług możesz za opłatą poszerzać o dodatkowe usługi wybierając odpowiednią opcję w szczegółach wybranego pakietu.
                    <br/>Dokonując zakupu pakietu usług w chwili składania zamówienia umówisz dogodny dla Ciebie termin jego realizacji korzystając z udostępnionego Ci kalendarza.
                    <br/>
                    <br/>3)	Voucher prezentowy to opcja zakupu pakietu usług dla wybranej osoby z przeznaczeniem na prezent. Dzięki zakupowi vouchera prezentowego możesz sprezentować konkretnej osobie oferowane za pośrednictwem naszej aplikacji pakiety usług naszych kontrahentów. Korzystając z tej możliwości nie musisz rezerwować terminu realizacji usługi w momencie zakupu. Obdarowany sam wybierze dogodny dla niego termin realizacji.
                    <br/>Wystarczy, że kupując voucher prezentowy wskażesz imię i nazwisko osoby, która będzie przez Ciebie obdarowana.
                    <br/>Uwaga: vouchery prezentowe przeznaczone są do obdarowywania osób trzecich, dlatego wyłączamy możliwość zakupienia vouchera prezentowego dla samego siebie. W związku z tym wymagamy aby Twoje dane były różne od danych osoby wskazanej przez Ciebie na voucherze.
                    <br/>Zakupiony za pośrednictwem naszej aplikacji voucher prezentowy jest ważny aż 6 miesięcy od daty zakupu. Oznacza to, że osoba obdarowana może zaplanować do realizacji otrzymany voucher prezentowy przez 6 miesięcy od daty zakupu tego vouchera.
                    <br/>Zakupiony przez Ciebie voucher prezentowy zostanie przez nas wysłany w formie pliku pdf na wskazany przez Ciebie adres e-mail.
                    <br/>Obdarowany może zrealizować voucher prezentowy osobiście - bezpośrednio w placówce naszego kontrahenta, lub bezpośrednio w aplikacji internetowej. Aby zrealizować voucher w aplikacji internetowej zeskanuj kod QR umieszczony na voucherze lub ręcznie wpisz kod vouchera.
                    <br/>
                    <br/><h2>Jak dokonać zakupu wybranej usługi</h2>
                    <br/>Zakup wybranej usługi/ pakietu usług / vouchera prezentowego odbywa się w kilku prostych krokach:
                    <br/>1)	Dodaj do koszyka wybraną usługę i ewentualnie w miarę potrzeb poszerz ją o wybrane dodatkowe opcje (usługi)
                    <br/>2)	Wybierz dogodny termin i godzinę realizacji lub w przypadku zakupu vouchera prezentowego podaj imię i nazwisko osoby obdarowanej która będzie realizowała voucher.
                    <br/>3)	Podaj nam swoje imię, nazwisko, adres e-mail i numer telefonu.
                    <br/>4)	Jeżeli dokonujesz zakupu dla firmy zaznacz, że potrzebujesz faktury i podaj nam dane do jej wystawienia.
                    <br/>5)	Zaakceptuj nasz regulamin i politykę prywatności.
                    <br/>6)	Wybierz jedną z dostępnych metod płatności i opłać zamówienie.
                    <br/>
                    <br/>Po przyjęciu przez Nas twojego zamówienia dostaniesz e-mailowe potwierdzenie zakupu oraz ewentualnie (jeżeli wybrano tę opcję) fakturę od naszego kontrahenta.
                    <br/>
                    <br/><h2>Jak dokonać rezerwacji terminu</h2>
                    <br/>Dokonując zakupu usług / pakietu usług zarezerwuj przy pomocy naszego kalendarza termin ich realizacji. Najpóźniej 24h przed terminem realizacji usługi możesz dokonać zmiany terminu usługi. W tym celu skontaktuj się bezpośrednio z naszym kontrahentem w celu ustalenia dogodnego dla Ciebie terminu.
                    <br/>
                    <br/><h2>Jakie są metody płatności</h2>
                    <br/>Dokonując zakupu za pośrednictwem naszej aplikacji możesz skorzystać z następujących metod płatności:
                    <br/>1)	blik,
                    <br/>2)	karty płatnicze: Visa, Visa Electron, Mastercard, MasterCard Electronic, Maestro, <img src='https://static.paynow.pl/brand/mastercard_visa.gif' alt='Mastercard and Visa' width='123' height='123'/>
                    <br/>3)	szybki przelew.
                    <br/>
                    <br/>W celu opłacenia złożonego zamówienia zostaniesz przekierowany do strony pośrednika płatności. Od chwili przekierowania, pośrednik płatności jest Administratorem Twoich danych osobowych. Po dokonaniu płatności otrzymasz e-mailowe potwierdzenie pomyślnej realizacji płatności bezpośrednio od pośrednika płatności.
                    <br/>Podmiotem świadczącym obsługę płatności online w zakresie płatności kartami jest Blue Media S.A.
                    <br/>
                    <br/><h2>O czym należy wiedzieć przed zakupem</h2>
                    <br/>1)	Czas realizacji Twojego zamówienia jest liczony od momentu uzyskania pozytywnej autoryzacji płatności.
                    <br/>2)	Przed zakupem zapoznaj się ze szczegółami wybranego pakietu/usługi oraz Regulaminem korzystania z wybranej przez Ciebie strzelnicy. Korzystanie z niektórych z oferowanych przez nas usług może być uzależnione od takich kryteriów jak np. osiągnięcie określonego wieku czy zachowanie trzeźwości. Koniecznie sprawdź, czy spełniasz je wszystkie.
                    <br/>3)	Jeżeli został Ci przyznany kod rabatowy, pamiętaj aby w trakcie składania zamówienia wpisać jego treść w odpowiednie pole w celu uzyskania rabatu. Przyznany kod rabatowy może zawierać ograniczenia czasowe lub kwotowe. Przed użyciem zapoznaj się dokładnie z jego treścią.
                    <br/>4)	Przy zakupie podaj nam prawdziwe dane, w szczególności imię, nazwisko, nr telefonu, adres e-mail.
                    <br/>5)	Pamiętaj, że oferowane przez nas usługi nie są przeznaczone do profesjonalnego handlu.
                    <br/>6)	Zakazane jest powielanie zakupionych pakietów usług lub voucherów prezentowych.
                    <br/>7)	Zastrzegamy sobie prawo do wyeliminowania z obrotu voucherów prezentowych lub pakietów usług które zostały wytworzone w sposób sprzeczny z Regulaminem lub prawem.
                    <br/>8)	Przeglądając zawartość serwisu zawsze jesteś informowany o cenach poszczególnych usług. Obowiązują Cię ceny oferowane w chwili złożenia zamówienia. Wszystkie podawane przez nas ceny są cenami brutto zawierającymi podatek VAT.
                    <br/>9)	Podczas składania zamówienia masz możliwość dołączenia do naszego newslettera.
                    <br/>
                    <br/><h2>Wykorzystywanie zakupionych usług</h2>
                    <br/>Pamiętaj, że jeżeli z treści informacji zawartej w ofercie usługi (pakietu usług) lub niniejszego Regulaminu nie wynika nic innego to:
                    <br/>1)	oferowane przez nas usługi mają charakter jednorazowy. Należy zrealizować je w całości, bowiem nie podlegają one realizacji częściowej lub stopniowej.
                    <br/>2)	wybrane usługi (pakiety usług) możliwe są do realizacji wyłącznie u naszego kontrahenta, w miejscu wskazanym w treści oferty,
                    <br/>3)	jedna usługa (pakiet usług) jest ważny tylko dla jednej osoby.
                    <br/>
                    <br/>Jeśli z powodu nieprzewidzianych okoliczności nasz kontrahent nie będzie w stanie świadczyć na Twoją rzecz usług objętych zakupioną usługą (pakietem) zgodnie z opisem, niezwłocznie powiadomimy Cię o tym za pomocą e-mail bądź telefonicznie.
                    <br/>
                    <br/><h2>Odstąpienie od umowy</h2>
                    <br/>1)	Jeżeli kupiłeś za pośrednictwem naszej aplikacji usługę jako konsument, przysługuje Ci prawo odstąpienia od umowy w ciągu 14 dni od dnia zakupu. W takim wypadku zwrócimy Ci cenę sprzedaży w ciągu 14 dni od daty otrzymania przez nas zgłoszenia. W celu odstąpienia od umowy skontaktuj się z nami pisemnie, e-mailem lub telefonicznie na adresy i numery wskazane na początku Regulaminu. W celu odstąpienia od umowy możesz skorzystać z udostępnionego przez nas wzoru formularza odstąpienia od umowy stanowi załącznik nr 1 do niniejszego Regulaminu.
                    <br/>2)	Dokonamy zwrotu płatności przy użyciu takiego samego sposobu płatności, jakiego użyłeś przy składaniu zamówienia, chyba że wyraźnie zgodzisz się na inny sposób zwrotu, który nie wiąże się z żadnymi kosztami.
                    <br/>3)	Z przyczyn organizacyjnych prosimy, abyś w miarę możliwości poinformował nas o skorzystaniu z prawa odstąpienia od umowy najpóźniej na 24 godziny przed terminem realizacji usługi.
                    <br/>4)	Z uwagi na charakter oferowanych usług, sprzedawane za pośrednictwem naszego portalu usługi, pakiety usług i vouchery nie mogą zostać zwrócone po ich zrealizowaniu w wybranym przez Ciebie terminie. Wynika to z art. 38 pkt. 1 ustawy o prawach konsumenta zgodnie z którym, po wykonaniu usługi nie będzie przysługiwało ci prawo do odstąpienia od umowy zawartej na odległość. Zachowasz natomiast prawo do reklamacji.
                    <br/>5)	Pamiętaj, że  prawo do odstąpienia od umowy zawartej na odległość przysługuje wyłącznie konsumentom. Jeśli jesteś przedsiębiorcą, a zakup w naszej aplikacji miał związek z prowadzoną przez Ciebie działalnością nie będzie przysługiwało Ci prawo odstąpienia od umowy.
                    <br/>
                    <br/><h2>Nasza odpowiedzialność</h2>
                    <br/>1)	Za pośrednictwem naszej aplikacji prezentujemy informacje, które otrzymaliśmy od naszych kontrahentów. Kontrahenci, którzy wprowadzają do sprzedaży usługi lub pakiety usług za pośrednictwem naszej aplikacji mają dostęp do naszych systemów poprzez które zobowiązani są do uaktualniania wszystkich cen/opłat, terminów, zasad i warunków oraz innych istotnych informacji, które pojawiają się w naszej aplikacji. Dokładamy wszelkich starań aby ujawnione w aplikacji informacje były przedstawione w sposób rzetelny i profesjonalny. Nie jesteśmy w stanie sprawdzić i zagwarantować, że wszystkie informacje nam dostarczone są dokładne, kompletne i poprawne. Każdy kontrahent przez cały czas pozostaje odpowiedzialny za dokładność, kompleksowość i poprawność (opisowych) informacji (włącznie z cenami/opłatami, zasadami i warunkami oraz dostępnością) wyświetlanych w naszej aplikacji Nasz kontrahent zobowiązuje się do realizacji usługi zgodnie z opisem umieszczonym w naszej aplikacji.
                    <br/>
                    <br/><h2>Reklamacje</h2>
                    <br/>1)	W przypadku jakichkolwiek reklamacji dotyczących realizacji usług, najlepiej spróbuj je wyjaśnić bezpośrednio z naszym kontrahentem. Każdą reklamację możesz przekazać pisemnie, e-mailem lub na inne dane kontaktowe wskazane w ofercie. Nasz kontrahent powinien rozpatrzyć Twoją reklamację jak najszybciej, nie powinno mu to zająć dłużej niż 14 dni. Odpowiedź na reklamację otrzymasz na adres e-mail podany przy składaniu zamówienia.
                    <br/>2)	W razie problemów przy złożeniu reklamacji bezpośrednio u kontrahenta, możesz złożyć ją również za naszym pośrednictwem, kierując ją na nasze dane adresowe wskazane na początku tego Regulaminu. Jeżeli podane w reklamacji dane lub informacje będą wymagały uzupełnienia, przed jej rozpatrzeniem, zwrócimy się do Ciebie z prośbą o ich uzupełnienie we wskazanym zakresie.
                    <br/>
                    <br/><h2>Prawa własności intelektualnej</h2>
                    <br/>1)	Opisy, a także inne informacje w możliwie najdokładniejszym zakresie odzwierciedlają usługi oferowane przez naszych kontrahentów za pośrednictwem naszej aplikacji. Wszelkie prawa do aplikacji w szczególności prawa własności intelektualnej do nazwy, znaków towarowych, domeny, formy, grafiki, artykułów, zdjęć, należą do nas bądź naszych kontrahentów. Ich kopiowanie, rozpowszechnianie może naruszać nasze prawa lub prawa naszych partnerów handlowych.
                    <br/>
                    <br/><h2>Prawo właściwe i polubowne rozwiązywanie sporów</h2>
                    <br/>1)	Prawem właściwym dla niniejszego Regulaminu jest prawo polskie, Zasady korzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń możesz sprawdzić pod adresem: https://uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php.
                    <br/>2)	Przy Prezesie Urzędu Ochrony Konkurencji i Konsumentów działa także punkt kontaktowy (telefon: 22 55 60 333, email: kontakt.adr@uokik.gov.pl lub adres pisemny: Pl. Powstańców Warszawy 1, Warszawa.), którego zadaniem jest między innymi udzielanie pomocy konsumentom w sprawach dotyczących pozasądowego rozwiązywania sporów konsumenckich.
                    <br/>3)	Przykładowo, przysługują Ci następujące możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
                    <br/>a)	wniosek o rozstrzygnięcie sporu do stałego polubownego sądu konsumenckiego (więcej informacji na stronie: http://spsk.wiih.org.pl/index.php?id=102);
                    <br/>b)	wniosek w sprawie pozasądowego rozwiązania sporu do wojewódzkiego inspektora Inspekcji Handlowej (więcej informacji na stronie inspekcji handlowej)
                    <br/>c)	pomoc powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
                    <br/>4)	Pod adresem http://ec.europa.eu/consumers/odr dostępna jest platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR). Platforma ODR stanowi interaktywną i wielojęzyczną stronę internetową z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług (więcej informacji na stronie samej platformy lub pod adresem internetowym Urzędu Ochrony Konkurencji i Konsumentów: https://uokik.gov.pl/spory_konsumenckie_faq_platforma_odr.php).
                    <br/>
                    <br/><h2>Wzór formularza odstąpienia od umowy</h2>
                    <br/>Pobierz: <a href={"/wzor_odstapienia_od_umowy.pdf"}>Formularz zwrotu</a>
                </div>
            </Dialog>
            <span onClick={() => setIsDialogOpen(true)}>Regulamin rezerwacji</span>
        </div>
    );
};

export default TermsDialog;
