import './App.scss';
import HomePage from "./pages/HomePage";
import './App.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import KobePage from "./pages/KobePage";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/kobe" element={<KobePage/>}/>
                <Route path="*" element={<HomePage/>}/>
            </Routes>
        </Router>
        // <div className="App"><HomePage/></div>
    );
}

export default App;
