import React, {useState} from 'react';
import {Dialog} from "primereact/dialog";
import './Dialog.scss';

const PrivacyDialog = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    return (
        <div className={"dialog-wrapper"}>
            <Dialog visible={isDialogOpen} onHide={() => setIsDialogOpen(false)} style={{width: "100%"}}>
                <div className={"dialog-content"}>
                    <br/><br/><h1>POLITYKA PRYWATNOŚCI</h1>
                    <br/>Celem niniejszej Polityki Prywatności jest wyjaśnienie zasad, na jakich przetwarzane będą Twoje dane osobowe udostępnione nam za pomocą formularza zamówienia na stronie: https://demo.bookit.one przez aplikację Bookit.One.
                    <br/>
                    <br/>Administratorem Twoich danych osobowych jest Bookit.One spółka z ograniczoną odpowiedzialnością z siedzibą we Wrocławiu, adres: ul. Kminkowa 35/12, 51-180 (zwany dalej Administratorem), NIP: 8982267179, REGON: 389327573, KRS: 0000908641.
                    <br/>Z Administratorem danych możesz się skontaktować:
                    <br/>a)	listownie, na następujący adres: ul. Kminkowa 35/12, 51-180 Wrocław ,
                    <br/>b)	za pośrednictwem poczty elektronicznej pod adresem: kontakt@bookit.one
                    <br/>
                    <br/>Administrator nie wyznaczył Inspektora ochrony danych osobowych. Kontakt w sprawie danych osobowych możliwy jest pod adresem poczty elektronicznej: kontakt@bookit.one lub w formie pisemnej na podany powyżej adres Administratora.
                    <br/>
                    <br/>Podmiotem, który zbiera oraz przetwarza dane w aplikacji Bookit.One w imieniu Administratora jest Bookit.One spółka z ograniczoną odpowiedzialnością z siedzibą we Wrocławiu adres: ul. Kminkowa 35/12, 51-180 Wrocław (zwana dalej Bookit.One) która działa jako podmiot przetwarzający na podstawie umowy powierzenia przetwarzania danych osobowych zawartej z Administratorem.
                    <br/>
                    <br/><h2>Ogólne zasady przetwarzania danych osobowych</h2>
                    <br/>Zasady regulujące przetwarzanie danych osobowych stanowią ramy, które gwarantują, że dane osobowe używane będą we właściwy sposób. Administrator oraz Bookit.One zobowiązują się dbać o to, by Twoje dane osobowe były:
                    <br/>1.	przetwarzane rzetelnie, zgodnie z prawem i w sposób przejrzysty;
                    <br/>2.	prawidłowe, stosowne oraz nienadmierne ilościowo;
                    <br/>3.	dokładne i aktualne – niedokładne lub niekompletne dane osobowe będą poprawiane lub usuwane bądź ich dalsze przetwarzanie zostanie wstrzymane;
                    <br/>4.	przechowywane przez czas nie dłuższy niż to konieczne;
                    <br/>5.	przetwarzane jedynie w określonym, jednoznacznym i zgodnym z prawem celu oraz zgodnie z celem, w jakim są gromadzone;
                    <br/>6.	bezpieczne.
                    <br/>
                    <br/><h2>Rodzaje przetwarzanych danych osobowych</h2>
                    <br/>Będziemy przetwarzać Twoje dane osobowe podane w aplikacji Bookit.One. Dane te obejmują:
                    <br/>1.	dane identyfikacyjne (imię i nazwisko);
                    <br/>2.	dane kontaktowe (adres e-mail oraz numer telefonu);
                    <br/>3.	dane dot. płatności i związane z wystawieniem faktury.
                    <br/>4.	informacje o użytkowniku, na przykład dane techniczne dotyczące użytkowania i przeglądania, w tym adresy IP, z których Internauci odwiedzają nasze strony internetowe lub aplikacje, z uwzględnieniem stron podmiotów zewnętrznych.
                    <br/>
                    <br/>Zawsze informujemy, które dane osobowe są wymagane (poprzez zaznaczenie ich gwiazdką (*). Niepodanie wymaganych danych, uniemożliwi Ci złożenie zamówienia za pomocą aplikacji Bookit.One.
                    <br/>
                    <br/>W każdym z tych przypadków przetwarzamy jedynie te dane, które otrzymaliśmy od Ciebie.
                    <br/>
                    <br/>Bookit.One wykorzystuje (jak również zbiera informacje) o odwiedzających sklep Administratora osobach jedynie w takim zakresie, w jaki jest to niezbędne do świadczenia usług na najwyższym poziomie. W zakresie uzyskiwania danych statystycznych i analitycznych dotyczących odwiedzin sklepu, Bookit.One  zbiera pliki cookies na zasadach określonych w polityce cookies oraz korzysta z usługi firmy Google LLC w postaci Google Analitics. Jednakże w takim wypadku Bookit.One nie uzyskuje dostępu do danych osobowych, bowiem dane te nie pozwalają na identyfikację konkretnej osoby.
                    <br/>
                    <br/><h2>Cele przetwarzania danych osobowych</h2>
                    <br/>Administrator przetwarzania Państwa dane osobowe w celach zgodnych z prawem i zasadniczo robimy to tylko, jeśli:
                    <br/>1.	Jeśli wyraziłeś zgodę na przetwarzanie danych;.
                    <br/>2.	Przetwarzanie danych jest konieczne do realizacji umowy.
                    <br/>3.	Przetwarzanie danych jest niezbędne do wykonania ciążącego obowiązku prawnego Administratora.
                    <br/>4.	Przetwarzanie danych jest konieczne dla potrzeb wynikających z uzasadnionych interesów Administratora  lub uzasadnionych interesów podmiotów zewnętrznych oraz nie jest uważane za szkodliwe dla użytkownika, włączając w to nasze interesy w kontekście rozwijania, oceniania, oferowania i sprzedaży naszych produktów i usług, nawiązywania, rozwijania i utrzymywania relacji biznesowych z klientami, dostawcami i innymi kontaktami biznesowymi.
                    <br/>
                    <br/>Administrator na podstawie umowy powierzenia przetwarzania danych osobowych upoważnił Bookit.One do zbierania i przetwarzania Twoich danych za pomocą aplikacji w następujących celach:
                    <br/>1.	Pośrednictwo w zawarciu umowy sprzedaży oferowanych usług przez Administratora oraz płatności za usługę. Korzystanie z zasobów Bookit.One jest realizowane w celu zawarcia i wywiązania się z umów przez Administratora, na podstawie obowiązującego Regulaminu.
                    <br/>2.	Prezentacji usług świadczonych przez Administratora.
                    <br/>3.	Zapewnienia szeroko rozumianej obsługi klienta.
                    <br/>4.	Dokonania rezerwacji usługi u Administratora.
                    <br/>5.	Ustalenia, dochodzenia i obrony przed roszczeniami, związanymi z zawartą umową lub przetwarzaniem danych osobowych,
                    <br/>6.	Wypełnienia ciążących na Administratorze obowiązków prawnych wynikających z przepisów prawa.
                    <br/>7.	Jeżeli wyraziłeś zgodę na otrzymywanie newsletter-a, Administrator przetwarza Twoje dane osobowe w celu prowadzenia marketingu jego produktów i usług.
                    <br/>
                    <br/><h2>Zgoda</h2>
                    <br/>Otrzymywanie newsletter-a jest dokonywane na podstawie udzielonej przez Ciebie zgody. W tym celu konieczne jest przetwarzanie Twojego adresu e-mail. Wyrażenie zgody i podanie adresu e-mail jest dobrowolne jednak niezbędne w celu jego wysyłania. Masz prawo do wycofania swojej zgody w dowolnym czasie,  w tym celu należy w otrzymanej wiadomości wybrać opcję rezygnacji z otrzymywania newsletter-a lub skontaktować się z Administratorem na wskazane powyżej  jego dane kontaktowe. Jeżeli wycofasz zgodę, zaprzestaniemy przetwarzania Twoich danych osobowych w tym celu. Należy zwrócić uwagę, że wycofanie zgody nie będzie miało wpływu na zgodność z prawem procesu przetwarzania danych przed jej wycofaniem. Twój adres e-mail w tym celu będzie przetwarzany tak długo, jak będzie działała usługa newsletter-a. W każdym czasie przysługuje Tobie możliwość zrezygnowania z usługi,
                    <br/>
                    <br/><h2>Ujawnianie, przekazywanie i udostępnianie danych osobowych podmiotom zewnętrznym</h2>
                    <br/>Administrator nie będzie ujawniał przekazanych nam danych osobowych podmiotom trzecim, za wyjątkiem:
                    <br/>1.	Bookit.One - która zapewnia możliwość złożenia i obsługi zamówienia na usługi Administratora;
                    <br/>2.	Operator płatności (Blue Media S.A.) - który jest podmiotem świadczącym obsługę płatności online w zakresie płatności kartami.
                    <br/>
                    <br/>Działanie Bookit.One  dokonywane na zlecenie Administratora opiera się na pośrednictwie i umożliwienie zrobienia zamówienia usług Administratora oraz ich obsługę. Momentem, w którym dochodzi do przekazania danych do Administratora, jest opłacenie przez Ciebie zamówionej usługi.
                    <br/>
                    <br/>Administrator zastrzega sobie również prawo do ujawniania lub udostępniania danych osobowych podmiotom zewnętrznym w następujących okolicznościach:
                    <br/>1.	gdy podmioty zewnętrzne świadczą w jego imieniu usługi, na przykład wsparcie informatyczne, analityczne, oraz usługi hostingowe itp. Tego rodzaju podmioty zewnętrzne mogą przetwarzać ujawnione, przekazane lub udostępnione im dane osobowe wyłącznie na zasadach określonych przez nas i na podstawie umowy o powierzeniu przetwarzania danych;
                    <br/>
                    <br/>Podmioty zewnętrzne świadczące usługi dla Bookit.One:
                    <br/>a.	Google Ireland Limited w celu korzystania z usług Google Analitics
                    <br/>b.	DigitalOcean LLC w celu dostarczenia usług hostingowych
                    <br/>c.	OVH Sp. z o.o. w celu dostarczenia usług mailingowych
                    <br/>
                    <br/>2.	doradcom prawnym - w celu ustanowienia, wykonania lub obrony naszych praw.
                    <br/>
                    <br/><h2>Przekazywanie danych do państw trzecich i organizacji międzynarodowych</h2>
                    <br/>Co do zasady Twoje dane nie będą przekazywane do państw trzecich ani organizacji międzynarodowych poza obszar EOG. Jednakże w przypadku niektórych dostawców, np. Google, lub dostawców mających siedzibę poza EOG może się zdarzyć, że dane będą przekazywane poza terytorium EOG.
                    <br/>
                    <br/>W takim przypadku przekazywanie danych odbywać się będzie wyłącznie w oparciu o stosowną umowę pomiędzy nami a tym podmiotem, zawierającą standardowe klauzule ochrony danych przyjęte przez Komisję Europejską, z tym zastrzeżeniem, iż każdorazowo przed jakimkolwiek przekazaniem danych oraz biorąc pod uwagę okoliczności towarzyszące temu przekazywaniu, dokonujemy oceny, czy podmioty te gwarantują stopień ochrony merytoryczny równoważny temu gwarantowanemu na terenie Unii Europejskiej przez przepisy RODO.
                    <br/>
                    <br/><h2>Podejmowanie decyzji opartych na zautomatyzowanym przetwarzaniu danych, w tym profilowaniu</h2>
                    <br/>Twoje dane nie są wykorzystywane do podejmowania decyzji opartych na zautomatyzowanym przetwarzaniu danych, a które mogłyby mieć wpływ na Twoją sytuację prawną lub wywołać dla Ciebie inne podobne doniosłe skutki. W każdym przypadku, gdy dojdzie do zmiany sytuacji i rozpoczęcia wykorzystywania zautomatyzowanego wykorzystywania Twoich danych, niezwłocznie o tym poinformujemy.
                    <br/>
                    <br/><h2>Termin usunięcia danych</h2>
                    <br/>Twoje dane osobowe będą przetwarzane przez Administratora przez czas trwania umowy lub do momentu cofnięcia zgody na newsletter, a następnie do czasu przedawnienia roszczeń wynikających z realizacji umowy i usługi pośrednictwa lub świadczenia usługi newslettera. Po upływie tego czasu Twoje dane zostaną przez nas usunięte.
                    <br/>
                    <br/><h2>Twoje prawa</h2>
                    <br/>Jako Administrator Twoich danych, zapewniamy Tobie:
                    <br/>a)	prawo do uzyskania dostępu do Twoich danych oraz szeregu informacji (Artykuł 15 RODO).
                    <br/>b)	prawo żądania od Administratora niezwłocznego sprostowania dotyczących Twoich danych osobowych, które są nieprawidłowe (Artykuł 16 RODO).
                    <br/>c)	prawo żądania od Administratora niezwłocznego usunięcia dotyczących Twoich danych osobowych (Artykuł 17 RODO)
                    <br/>d)	prawo żądania od Administratora ograniczenia przetwarzania danych (Artykuł 18 RODO):
                    <br/>e)	prawo do przenoszenia danych osobowych (Artykuł 20 RODO),
                    <br/>f)	prawo do wniesienia sprzeciwu wobec przetwarzania (Artykuł 21 RODO),
                    <br/>g)	prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych w przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO.
                    <br/>Administrator danych osobowych umożliwia Tobie, korzystanie z wymienionych wyżej uprawnień na zasadach określonych w przepisach Rozporządzenia unijnego o ochronie danych osobowych. W sytuacji wystąpienia przez z powyższymi uprawnieniem wynikającym z powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w ciągu miesiąca po jego otrzymaniu. Jeżeli jednak - z uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł spełnić żądania w ciągu miesiąca, spełni je w ciągu kolejnych dwóch miesięcy informując Państwa uprzednio w terminie miesiąca od otrzymania żądania - o zamierzonym przedłużeniu terminu oraz jego przyczynach
                    <br/>
                    <br/>Jeżeli chcesz skorzystać z któregokolwiek z tych uprawnień – możesz skierować swoje żądania do Administratora danych, korzystając z danych kontaktowych lub do Bookit.One, który zobowiązany jest do przekazania Twojego żądania do Administratorowi.
                    <br/>
                    <br/><h2>POLITYKA COOKIES</h2>
                    <br/>Polityka Cookies omawia różne rodzaje plików cookies wykorzystywanych w ramach świadczenia przez nas usług na tej stronie internetowej i wyjaśnia w jaki sposób możesz je kontrolować.
                    <br/>
                    <br/>1.	Możemy zbierać dane za pomocą plików cookies lub podobnych technologii. W ramach naszych usług sieciowych i mobilnych stosujemy technologie umożliwiające zbieranie informacji i ulepszanie doświadczeń użytkowników naszej strony. Te technologie, zawierające również pliki cookies, nazywamy łącznie „cookies”.
                    <br/>2.	Poprzez korzystanie z naszej strony i zapoznanie się z powiadomieniem o plikach cookies zgadzasz się na przechowywanie i wykorzystanie przez nas cookies zgodnie z niniejszą polityką. Jeżeli masz dodatkowe pytania lub wątpliwości możesz się z nami skontaktować w tej kwestii pod adresem e-mail: kontakt@bookit.one.
                    <br/>3.	Pliki cookies to małe pliki tekstowe przechowywane na Twoim urządzeniu (np. komputerze lub telefonie), powszechnie stosowane do usprawniania działania i zwiększania wydajności stron internetowych i usług sieciowych. Jest to możliwe dzięki temu, że strony i usługi sieciowe mogą odczytywać i zapisywać te pliki, co pozwala im na rozpoznanie Twojego urządzenia i zapamiętanie ważnych informacji, które umożliwiają Tobie wygodniejsze korzystanie z nich (np. zapamiętanie Twoich ustawień strony www).
                    <br/>4.	Na naszej stronie możemy wykorzystywać następujące rodzaje plików cookies:
                    <br/>a.	„Niezbędne” pliki cookies są niezbędne do prawidłowego działania naszych usług, umożliwiając poruszanie się po nich i korzystanie z ich funkcjonalności. Bez tych cookies usługi, z których chcesz korzystać, takie jak dostęp do bezpiecznych obszarów strony internetowej, nie mogą być świadczone. Te pliki cookies są niezbędne do korzystania z naszych usług, ale mogą zostać przez Ciebie wyłączone. Chcemy jednak, żebyś wiedział, że ich wyłączenie może mieć znaczący i negatywny wpływ na sposób, w jaki będziemy w stanie świadczyć dla Ciebie nasze usługi.
                    <br/>b.	pliki cookies zapewniające bezpieczeństwo platformy, używane w celu monitorowania nadużyć,
                    <br/>c.	Pliki cookies, których celem jest poprawa wydajności, często obejmują pliki analityczne, zbierają informacje na temat korzystania przez Ciebie z naszej strony i pozwalają nam usprawniać ich działanie, np. te pliki cookies pokazują najczęściej odwiedzane przez użytkowników części naszej strony internetowej. Korzystamy z tych plików cookies również, aby pomóc Ci w poruszaniu się po naszej stronie internetowej i powrocie do odwiedzanych kart.
                    <br/>d.	W pewnych sytuacjach możemy korzystać z funkcjonalnych plików cookies, które pozwalają nam zapamiętać wybory dokonane przez Ciebie w ramach korzystania z naszej strony i dostarczyć Ci ulepszone, bardziej spersonalizowane funkcje, takie jak język, wybrane produkty, dane formularza zamówienia.
                    <br/>e.	3’rd party cookies zamieszczane w serwisie przez zewnętrzne technologie. Korzystamy z narzędzia które gromadzi anonimowe dane dotyczące odwiedzanych stron takie jak: liczba odwiedzin, kraj, przeglądarka, czas wizyty itp. Analizujemy gromadzone dane korzystając z rozwiązania zewnętrznego dostawcy - Google Analytics. Narzędzie działa w oparciu o tzw. pliki cookie i nie udostępnia danych umożliwiających identyfikację osoby. Prosimy o zapoznanie się ze szczegółami polityki prywatności Google Analytics.
                    <br/>5.	W dowolnym momencie możesz wyłączyć pliki cookies. Wystarczy, że wybierzesz odpowiednie ustawienie w Twojej przeglądarce na komputerze lub urządzeniu mobilnym, które pozwala zablokować akceptowanie plików cookies lub podobnych technologii. Pamiętaj, że to Ty decydujesz. Więcej informacji odnośnie do sposobu w jaki powinieneś skonfigurować swoją przeglądarkę do korzystania z plików cookies w wybrany przez Ciebie sposób znajdziesz na stronie dostawcy Twojej przeglądarki dostępnej poprzez ekran pomocy. Pamiętaj, że w przypadku wyłączenia plików cookies, niektóre funkcje naszej strony mogą nie działać zgodnie z przeznaczeniem.
                </div>
            </Dialog>
            <span onClick={() => setIsDialogOpen(true)}>Polityka Prywatności</span>
        </div>
    );
};

export default PrivacyDialog;
