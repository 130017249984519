const Navigation = () => {
    return (
        <nav className="bg-black">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16">

                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex-shrink-0 flex items-center">
                            <a href={"/"}>
                                <span className={"font-4xl logo text-white"}>Bookit.one</span>
                            </a>
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                <a href={"/"}
                                   className={"border-secondary border-b-2 text-white px-3 py-2 text-sm font-medium"}>Strona główna</a>
                                <a  href="https://bookit.one/kontakt" target="_blank" rel="noreferrer" 
                                   className={"hover:border-secondary hover:border-b-2 text-white text-gray-300 px-3 py-2 text-sm font-medium "}>Kontakt</a>
                                <a target="_blank" rel="noreferrer" href={"https://bookit.one"}
                                   className={"hover:border-secondary hover:border-b-2 text-white text-gray-300 px-3 py-2 text-sm font-medium "}>Bookit.One</a>
                                <a href={"/"}
                                   data-id="00000000-0000-0000-0000-000000000000"
                                   data-color="7aa329"
                                   className={"bookitone-booking-init hover:bg-secondary hover:text-black border-secondary border-2 text-white px-3 py-2 rounded-md text-sm font-medium"}
                                   >Zarezerwuj</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"hidden"}>
                <div className="px-2 pt-2 pb-3 space-y-1">
                    <a href={"/"}
                          className={"border-secondary border-b-2 text-white block px-3 py-2  text-base font-medium"}>
                        Strona główna
                    </a>
                    <a href="https://bookit.one/kontakt" target="_blank" rel="noreferrer" 
                       className={"border-primary hover:border-secondary border-b-2 block px-3 py-2 text-white text-base font-medium"}>
                        Kontakt
                    </a>
                    <a target="_blank" rel="noreferrer" href={"https://bookit.one"}
                       className={"border-primary hover:border-secondary border-b-2 block px-3 py-2 text-white text-base font-medium"}>
                        Bookit.One
                    </a>
                    <a href={"/"}
                          className={"bookitone-booking text-white border-2 border-secondary border-solid hover:bg-secondary hover:text-black block px-3 py-2 rounded-md text-base font-medium"}>
                        Zarezerwuj
                    </a>
                </div>
            </div>
        </nav>

    );
};

export default Navigation;
