import logo from './home-main-image.jpg'
import './HomePage.scss'
import React from "react";

const KobePage = () => {
    return (
        <React.Fragment>

            <div id="home">
                <div className="md:mt-16 relative">
                    <div className="container mx-auto">
                        <div className="flex">
                            <div className="md:w-1/2 w-full h-2000">
                                <div className="md:mt-32 md:pl-4">
                                    <div className="relative md:hidden">
                                        <img src={logo} alt="Logo"/>
                                        <div className="image-overlay">
                                            <div className="text-white text-5xl mt-16">Strzelnica Pawłów</div>
                                            <div className="ml-4 text-left text-white mt-8 text-xl">Najlepsza strzelnica w okolicy.<br/>
                                                Strzelaj z najlepszymi!
                                            </div>
                                            <div className="ml-4 text-left mt-4">
                                                <a data-id="40bea4c2-27aa-45c2-9cc6-6a715df237cd" data-color="2b3645" className="bookitone-booking-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                    <button className="rounded-lg p-2 bg-secondary hover:bg-secondary-darker text-white">
                                                        Zarezerwuj pakiet
                                                    </button>
                                                </a>
                                            </div>
                                            <div className="ml-4 text-left mt-8">
                                                <a data-id="40bea4c2-27aa-45c2-9cc6-6a715df237cd" data-color="2b3645" className="bookitone-booking-range-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                    <button className="rounded-lg p-2 bg-secondary hover:bg-secondary-darker text-white">
                                                        Zarezerwuj oś
                                                    </button>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="text-dark text-6xl">Strzelnica Pawłów</div>
                                        <div className="text-dark mt-8 text-xl">Najlepsza strzelnica w mieście.<br/>
                                            Strzelaj z najlepszymi!
                                        </div>
                                        <div className="mt-4">
                                            <a data-id="40bea4c2-27aa-45c2-9cc6-6a715df237cd" data-color="2b3645" className="bookitone-booking-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                Zarezerwuj pakiet
                                            </a>
                                        </div>
                                        <div className="mt-4">
                                            <a data-id="40bea4c2-27aa-45c2-9cc6-6a715df237cd" data-color="2b3645" className="bookitone-booking-range-init text-green hover:border-b-2 border-green hover:border-solid text-xl">
                                                Zarezerwuj oś
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{fontSize: "0.7rem", color: "gray", border: "1px grey solid", borderRadius: "8px", padding: "8px", margin: "8px", marginTop: "2rem"}}>
                                        <h2>Uwaga!</h2>
                                        <p>
                                            Niniejsza strona służy jako demo przedstawiające możliwości integracji aplikacji BookitOne ze stronami współpracujących strzelnic.
                                            Funkcjonalność sklepu jest dostępna po kliknięciu przycisku "Zarezerwuj" lub "Zarezerwuj swoją przygodę".<br/>
                                        </p>
                                        <p>
                                            Wszystkie usługi i przypisane do nich ceny służą jedynie celom demonstracyjnym i nie ma możliwości ich zakupu.
                                            Okno płatności jest połączone z testowym środowiskiem (sandbox) bramki płatności Paynow. Przy procesowaniu płatności żadne pieniądze nie będą pobrane z Twojego konta.<br/>
                                        </p>
                                        <h2>Pamiętaj!</h2>
                                        <p>
                                            Podając jakiekolwiek dane na stronie demo, nie dojdzie do zawarcia umowy sprzedaży.
                                            Wszystkie dane podane w formularzu demo są chronione w takim samym stopniu jak w przypadku prawdziwego sklepu.
                                            Więcej informacji o przetwarzaniu danych znajdziesz w polityce prywatności.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 w-full hidden md:block">
                                <div className="bg-primary absolute right-0 top-0 pb-8 left-6/10">
                                    <img src={logo} alt="Logo" className="mr-16 mt-8 max"
                                         style={{'maxHeight': '500px', 'marginLeft': '-4rem'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*{*/}
                {/*    process.env.REACT_APP_VERSION &&*/}
                {/*    <div className={"footer"}>*/}
                {/*        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            {/* <Footer/> */}
        </React.Fragment>
    );
};

export default KobePage;
