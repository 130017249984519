import logo from './home-main-image.jpg'
import './HomePage.scss'
import React from "react";
import Navigation from "../components/Navigation/Navigation";
import Footer from "../components/Footer/Footer";

const HomePage = () => {
    return (
        <React.Fragment>
            <Navigation/>

        <div id="home">
            <div className="md:mt-16 relative">
                <div className="container mx-auto">
                    <div className="flex">
                        <div className="md:w-1/2 w-full h-2000">
                            <div className="md:mt-16 md:pl-4">
                                <div className="relative md:hidden">
                                    <img src={logo} alt="Logo"/>
                                    <div className="image-overlay">
                                        <div className="text-white text-5xl mt-8 font-bold">Strzelnica</div>
                                        <div className="text-white mt-2 text-xl">Najlepsza strzelnica w okolicy.<br/>
                                            Strzelaj z nami!
                                        </div>
                                        <div className="ml-4 text-left mt-6">
                                            <a href="#" data-id="00000000-0000-0000-0000-000000000000" data-color="7aa329" className="bookitone-booking-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                <button className="rounded p-2 bg-secondary hover:bg-secondary-darker text-white">
                                                    Zarezerwuj pakiet 
                                                </button>
                                           </a>
                                           </div>
                                        <div className="ml-4 text-left mt-2">  
                                           <a href="#"  data-id="00000000-0000-0000-0000-000000000000" data-color="7aa329" className="bookitone-booking-range-init text-secondary hover:border-b-2 border-secondary hover:border-solid text-xl">
                                                <button className="rounded p-2 bg-secondary hover:bg-secondary-darker text-white">
                                                    Zarezerwuj oś
                                                </button>
                                           </a>

                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block">
                                    <div className="text-dark text-header leading-header font-semibold">Strzelnica</div>
                                    <div className="text-dark mt-2 text-xl">Najlepsza strzelnica w mieście.<br/>
                                        Strzelaj z nami!
                                    </div>
                                    <div className="mt-4">
                                        <a href="#" data-id="00000000-0000-0000-0000-000000000000" data-color="7aa329" className="hover:bg-secondary-darker hover:border-secondary-darker bookitone-booking-init text-white border-secondary border-2 hover:border-solid bg-secondary p-3 rounded font-medium">
                                            Zarezerwuj pakiet
                                        </a>
                                        </div>
                                        <div className="mt-6">
                                        <a href="#" data-id="00000000-0000-0000-0000-000000000000" data-color="7aa329" className="hover:bg-grey-50 hover:border-secondary-darker hover:text-secondary-darker mt-2 bookitone-booking-range-init text-secondary hover:border-solid font-medium border-2 p-3 rounded border-secondary px-5">
                                            Zarezerwuj oś
                                        </a>
                                    </div>
                                </div>
                                <div style={{fontSize: "0.7rem", color: "gray", border: "1px grey solid", borderRadius: "8px", padding: "8px", margin: "8px", marginTop: "2rem"}}>
                                    <h2>Uwaga!</h2>
                                    <p>
                                    Niniejsza strona służy jako demo przedstawiające możliwości integracji aplikacji BookitOne ze stronami współpracujących strzelnic.
                                    Funkcjonalność sklepu jest dostępna po kliknięciu przycisku "Zarezerwuj" lub "Zarezerwuj swoją przygodę".<br/>
                                    </p>
                                    <p>
                                    Wszystkie usługi i przypisane do nich ceny służą jedynie celom demonstracyjnym i nie ma możliwości ich zakupu.
                                    Okno płatności jest połączone z testowym środowiskiem (sandbox) bramki płatności Paynow. Przy procesowaniu płatności żadne pieniądze nie będą pobrane z Twojego konta.<br/>
                                    </p>
                                    <h2>Pamiętaj!</h2>
                                    <p>
                                        Podając jakiekolwiek dane na stronie demo, nie dojdzie do zawarcia umowy sprzedaży.
                                        Wszystkie dane podane w formularzu demo są chronione w takim samym stopniu jak w przypadku prawdziwego sklepu.
                                        Więcej informacji o przetwarzaniu danych znajdziesz w polityce prywatności.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/2 w-full hidden md:block">
                            <div className="bg-black absolute right-0 top-0 pb-8 left-6/10">
                                <img src={logo} alt="Logo" className="mr-16 mt-8 max"
                                     style={{'maxHeight': '500px', 'marginLeft': '-4rem'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*{*/}
            {/*    process.env.REACT_APP_VERSION &&*/}
            {/*    <div className={"footer"}>*/}
            {/*        {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
            <Footer/>
        </React.Fragment>
    );
};

export default HomePage;
