import React from 'react';
import './Footer.scss';
import PrivacyDialog from "./PrivacyDialog";
import TermsDialog from "./TermsDialog";

const Footer = () => {
    return (
        <footer>
            <ul>
                <li><TermsDialog/></li>
                <li><PrivacyDialog/></li>
            </ul>
            <div className={"copyright"}>&copy; 2024 Bookit.One</div>
        </footer>
    );
};

export default Footer;
